import React from "react"

import WorkPageLayout from "./Components/WorkPageLayout"
import { Box, Flex } from "../../Components/Base"
import { WORK_ID, WORK } from "../../constant"

import Content from "./Components/Content"

const workId = WORK_ID.KUMAMOTO
const workData = WORK.find(w => w.key === workId)
const ROOT_IMAGE_PATH = `/assets/work/${workId}`

const HeaderSection = () => {
  return (
    <Box>
      <Content.Header>{workData.name}</Content.Header>
      <Content.SubHeader>role: {workData.role}</Content.SubHeader>
      <Flex flexDirection={["column", "row"]} justifyContent={"space-between"}>
        <Content.HeaderParagraph width={"70%"}>
          I was in the university team to join the Kumamoto Artpolis, an
          international design competition to design the “Spa Rehabilitation
          Annex with Home for All in Aso”, a rehabilitational space for senior
          people with recreational and sociable space for communnity in Aso. We
          proposed a new type of public space that engage people in various kind
          of activities and allow intimate conversations that bond people in the
          community together, to create a place where people can feel at home at
          “Home for All”.
        </Content.HeaderParagraph>
        <Content.TextField
          textAlign={"right"}
          size={"small"}
          fields={[
            {
              field: "location",
              value: "Aso, Japan",
            },
            { field: "program", value: "Pulic Space" },
            {
              field: "client",
              value: [
                "Kumamoto Artpolis, ",
                "an international design competition ",
                "for students in asia",
              ],
            },
            { field: "status", value: "completion 2014" },
          ]}
        />
      </Flex>
    </Box>
  )
}

const ContentSection = () => {
  return (
    <Content as={"article"}>
      <Content.Section>
        <Content.Image
          src={`/assets/work/${workId}/thumbnail.jpg`}
          height={"500px"}
          width={"100%"}
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </Content.Section>
      <Content.Section
        mb={0}
        fullWidth={false}
        left={
          <>
            <Content.Title>Investigating Context</Content.Title>
            <Content.Paragraph pr={5}>
              By investigating how people in our context spend their
              recreational time, we found out that highly sociable activities
              usually happened along the edge of the building on a space like a
              veranda which is where indoor meets outdoor space, under the shade
              but without walls, encouraging people to sit and begin to talk.
            </Content.Paragraph>
          </>
        }
        right={
          <Content.Image
            src={`${ROOT_IMAGE_PATH}/context.jpg`}
            style={{
              objectFit: "cover",
              objectPosition: "bottom",
            }}
          />
        }
      />

      <Content.Section mb={6}>
        <Content.DetailCard
          width={["100%", "50%", "25%"]}
          imageSrc={`${ROOT_IMAGE_PATH}/edge-diagram-01.png`}
          description={"edge of building - nature"}
          imageProps={{ height: "150px" }}
          descriptionProps={{ textAlign: "center" }}
        />
        <Content.DetailCard
          width={["100%", "50%", "25%"]}
          imageSrc={`${ROOT_IMAGE_PATH}/edge-diagram-02.png`}
          description={"edge of building - water"}
          imageProps={{ height: "150px" }}
          descriptionProps={{ textAlign: "center" }}
        />
        <Content.DetailCard
          textAlign={"center"}
          width={["100%", "100%", "50%"]}
          imageSrc={`${ROOT_IMAGE_PATH}/edge-diagram-03.png`}
          description={"edge of building - edge of building"}
          imageProps={{ height: "150px" }}
          descriptionProps={{ textAlign: "center" }}
        />
      </Content.Section>

      <Content.Section title={"Our Design"} mb={0}>
        <Content.Paragraph>
          Our design is, therefore, an attempt to maximize the veranda-like
          space for sociable activities and at the same time encompasses the
          landscape creating the ready-to-use outdoor space for main activities.
          However, the small budget limited the size and length of the building.
          Therefore, we thinned the width to maximize the edge of the building
          and the consideration of wheelchairs space for senior users, was used
          to find the possible minimum width.
        </Content.Paragraph>
      </Content.Section>

      <Content.Section mb={5}>
        <Content.DetailCard
          width={["100%", "50%", null]}
          imageSrc={`${ROOT_IMAGE_PATH}/diagram-01.svg`}
          index={1}
          description={"programming the buildings"}
        />
        <Content.DetailCard
          width={["100%", "50%", null]}
          imageSrc={`${ROOT_IMAGE_PATH}/diagram-02.svg`}
          index={2}
          description={"thinning the buildings to its minimum width"}
        />
        <Content.DetailCard
          width={["100%", "50%", null]}
          imageSrc={`${ROOT_IMAGE_PATH}/diagram-03.svg`}
          index={3}
          description={"encompassing the landscape with the buildings"}
        />
        <Content.DetailCard
          width={["100%", "50%", null]}
          imageSrc={`${ROOT_IMAGE_PATH}/diagram-04.svg`}
          index={4}
          description={"defining landscape for activities"}
        />
      </Content.Section>

      <Content.Section mb={3}>
        <Content.Image
          src={`/assets/work/${workId}/section-01.png`}
          width={["100%"]}
          caption={
            "sociable activities happened along the edge of the bulidings"
          }
        />
        <Content.Image
          src={`/assets/work/${workId}/tive-03.png`}
          width={["100%"]}
          caption={
            "sociable activities happened along the edge of the bulidings and outdoor spaces between them define other activities"
          }
        />

        <Content.Image src={`/assets/work/${workId}/plan.png`} width={"100%"} />
      </Content.Section>

      <Content.Section mb={5}>
        <Content.Paragraph>
          All outdoor spaces defined by the bulidings like courts are used for
          various types of activities such as sociable activities and relaixing
          garden based on the size and indoor activities.
        </Content.Paragraph>
        <Content.Image
          src={`/assets/work/${workId}/court-diagram.png`}
          width={"100%"}
          caption={"diagram showing the usage of outdoor space"}
        />
      </Content.Section>

      <Content.Section title={"Gallery"}>
        <Content.Image
          src={`/assets/work/${workId}/section-02.png`}
          width={["100%"]}
          caption={"section"}
        />

        <Content.Image
          src={`/assets/work/${workId}/tive-02.png`}
          width={["100%"]}
          caption={
            "all sizes of the buildings are designed based on the senior people"
          }
        />
        <Content.Image
          src={`/assets/work/${workId}/tive-01.png`}
          width={["100%"]}
          caption={"indoor and outdoor spaces are seamlessly integrated"}
        />
        <Content.Image
          src={`/assets/work/${workId}/model-10.jpg`}
          width={["100%"]}
        />
        <Content.Image
          src={`/assets/work/${workId}/model-03.png`}
          width={["100%", "50%"]}
        />
        <Content.Image
          src={`/assets/work/${workId}/model-08.jpg`}
          width={["100%", "50%"]}
        />
        <Content.Image
          src={`/assets/work/${workId}/model-09.jpg`}
          width={["100%", "50%"]}
        />
        <Content.Image
          src={`/assets/work/${workId}/model-01.png`}
          width={["100%", "50%"]}
        />
        {/* <Content.Image
          src={`/assets/work/${workId}/model-07.jpg`}
          width={["100%", "50%"]}
        /> */}
        <Content.Image
          src={`/assets/work/${workId}/model-05.jpg`}
          width={["100%", "50%"]}
        />
        <Content.Image
          src={`/assets/work/${workId}/model-06.jpg`}
          width={["100%", "50%"]}
        />
      </Content.Section>

      <Content.Section>
        <Content.TextField
          fields={[
            {
              field: "Team",
              value: [
                "Tadpol Rachatasumrit",
                "Kitsupphat Na Lampang",
                "Thanaphat Sangkharom",
                "Paphop Kerdsup",
                "Nathida Sornchumni",
              ],
            },
            {
              field: "Advisor",
              value: ["Asst. Prof Rachaporn Choochuey"],
            },
          ]}
        />
      </Content.Section>
    </Content>
  )
}
const Work = () => {
  return (
    <WorkPageLayout
      header={<HeaderSection />}
      fullWidthHeader={true}
      content={<ContentSection />}
      currentWork={workId}
    />
  )
}

export default Work
